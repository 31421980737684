<template>
  <div class="connent_title">
    <div class="connent_header">
      <span>角色管理</span>
    </div>
  </div>

  <el-form :inline="true" class="demo-form-inline">
    <el-form-item>
      <el-button type="primary" @click="onCreate" v-hasPermission="'/Api/Role/Create'">增加</el-button>
    </el-form-item>
  </el-form>

  <Form
      :params="dialog"
      @getTableData="getTableData"
      @onClose="onClose"/>

  <el-table
      :data="tableData"
      v-loading="loading"
      style="width: 100%;border: 1px solid #EBEEF5;border-radius: 5px;">
    <el-table-column
        v-for="item in tableOptions"
        :key="item.value"
        :label="item.label"
        :prop="item.value">
    </el-table-column>
    <el-table-column label="操作" min-width="110">
      <template #default="scope">
        <el-button-group>
          <el-button
              size="mini"
              type="primary"
              @click="onEdit(scope.$index, scope.row)">编辑
          </el-button>
          <el-popconfirm title="确定删除吗？" @confirm="onDelete(scope.row)" >
            <template #reference>
              <el-button
                  size="mini"
                  type="danger">删除
              </el-button>
            </template>
          </el-popconfirm>
        </el-button-group>

        <router-link v-hasPermission="'/Api/RoleAuthority/Modify'" :to="{path:'/User/RoleAuthority/List',query:{id:scope.row.id}}" style="margin: 4px">
          <el-button
              size="mini"
              type="primary">权限管理
          </el-button>
        </router-link>
      </template>
    </el-table-column>
  </el-table>

  <el-pagination
      background
      :pager-count="11"
      layout="total, prev, pager, next, jumper"
      :total="pageData.total"
      :page-size="pageData.size"
      :current-page="pageData.current"
      @current-change="changePage">
  </el-pagination>

</template>

<script>

import axios from '@/utils/axios'
import Form from './Form'
import {ElMessage} from 'element-plus'

export default {
  data() {
    return {
      loading: false,
      dialog: {
        // 是否显示dialog弹出表单
        show: false,
        // add or edit
        type: "",
        // dialog标题
        title: "",
        // dialog表单数据
        data: []
      },
      // 分页信息
      pageData: window.global.config.page,
      // 搜索区内容
      searchData: {
        name: '',
      },
      // 表格设置
      tableOptions: [{
        value: 'name',
        label: '名称',
      }, {
        value: 'createdDate',
        label: '创建时间',
      }],
      // 表格数据
      tableData: []
    }
  },
  components: {
    Form
  },
  created() {
    this.getTableData()
  },
  methods: {
    onSearch() {
      this.getTableData()
    },
    onCreate() {
      this.dialog.title = "增加角色";
      this.dialog.type = 'add'
      this.dialog.data = {}
      this.dialog.show = true;
    },
    onDelete(row) {
      this.loading = true
      axios.post('/Role/Remove', {
        id: row.id
      }).then(() => {
        ElMessage.success('删除成功')
        this.getTableData()
      }).catch(() => {
        this.getTableData()
      })
    },
    onEdit(index, row) {
      this.dialog.title = "修改角色";
      this.dialog.type = 'edit'
      this.dialog.data = row;
      this.dialog.show = true;
    },
    onClose() {
      this.dialog.show = false;
    },

    // 获取列表
    getTableData() {
      this.loading = true
      var that = this
      axios.get('/Role/List?status=all', {
        params: Object.assign({}, this.searchData, this.pageData)
      }).then(res => {
        that.tableData = res.data.list
        that.pageData.total = res.data.total
        that.loading = false
      }).catch(error => {
        that.loading = false
      })
    },

    changePage(page) {
      this.pageData.current = page
      this.getTableData()
    }

  }
}
</script>