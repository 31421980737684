<template>
  <el-dialog
      :title="params.title"
      :model-value="params.show"
      @close="onCacel"
      width="600px" center>
    <el-form :model="form" :rules="rules" ref="form" label-width="100px" class="demo-ruleForm">
      <el-form-item label="名称" prop="name">
        <el-input v-model="form.name" :placeholder="rules.name[0].message"></el-input>
      </el-form-item>

      <el-form-item>
        <el-button type="primary" @click="submitForm('form')">保存</el-button>
        <el-button @click="onCacel()">返回</el-button>
      </el-form-item>
    </el-form>
  </el-dialog>
</template>

<script>

import axios from '@/utils/axios'
import {ElMessage} from 'element-plus'

export default {
  name: "Form",
  props: {
    params: Object
  },
  data() {
    return {
      rules: {
        name: [
          {required: true, message: '请输入名称', trigger: 'blur'},
          {min: 3, max: 50, message: '长度在 3 到 50 个字符', trigger: 'blur'}
        ]
      },

    };
  },
  computed: {
    form: function () {
      return this.params.data
    }
  },
  emits: ["onClose","getTableData"],
  methods: {
    submitForm(formName) {
      var that = this

      this.$refs[formName].validate((valid) => {

        if (valid) {

          if (this.params.type == 'add') {
            axios.post('/Role/Create', this.form).then(() => {
              ElMessage.success('添加成功')
              that.$emit('onClose')
              that.$emit('getTableData')
            })
          } else {
            axios.post('/Role/Modify?id='+this.form.id, this.form).then(() => {
              ElMessage.success('修改成功')
              that.$emit('onClose')
              that.$emit('getTableData')
            })
          }
        }
      })
    },
    onCacel() {
      this.$emit('onClose')
    }
  }
}
</script>